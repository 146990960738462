module.exports={
  "_args": [
    [
      "bigi@1.4.2",
      "/home/sigve/Dev/Bitshares/bitsharesjs"
    ]
  ],
  "_from": "bigi@1.4.2",
  "_id": "bigi@1.4.2",
  "_inBundle": false,
  "_integrity": "sha1-nGZalfiLiwj8Bc/XMfVhhZ1yWCU=",
  "_location": "/bigi",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "bigi@1.4.2",
    "name": "bigi",
    "escapedName": "bigi",
    "rawSpec": "1.4.2",
    "saveSpec": null,
    "fetchSpec": "1.4.2"
  },
  "_requiredBy": [
    "/",
    "/ecurve"
  ],
  "_resolved": "https://registry.npmjs.org/bigi/-/bigi-1.4.2.tgz",
  "_spec": "1.4.2",
  "_where": "/home/sigve/Dev/Bitshares/bitsharesjs",
  "bugs": {
    "url": "https://github.com/cryptocoinjs/bigi/issues"
  },
  "dependencies": {},
  "description": "Big integers.",
  "devDependencies": {
    "coveralls": "^2.11.2",
    "istanbul": "^0.3.5",
    "jshint": "^2.5.1",
    "mocha": "^2.1.0",
    "mochify": "^2.1.0"
  },
  "homepage": "https://github.com/cryptocoinjs/bigi#readme",
  "keywords": [
    "cryptography",
    "math",
    "bitcoin",
    "arbitrary",
    "precision",
    "arithmetic",
    "big",
    "integer",
    "int",
    "number",
    "biginteger",
    "bigint",
    "bignumber",
    "decimal",
    "float"
  ],
  "main": "./lib/index.js",
  "name": "bigi",
  "repository": {
    "url": "git+https://github.com/cryptocoinjs/bigi.git",
    "type": "git"
  },
  "scripts": {
    "browser-test": "mochify --wd -R spec",
    "coverage": "istanbul cover ./node_modules/.bin/_mocha -- --reporter list test/*.js",
    "coveralls": "npm run-script coverage && node ./node_modules/.bin/coveralls < coverage/lcov.info",
    "jshint": "jshint --config jshint.json lib/*.js ; true",
    "test": "_mocha -- test/*.js",
    "unit": "mocha"
  },
  "testling": {
    "files": "test/*.js",
    "harness": "mocha",
    "browsers": [
      "ie/9..latest",
      "firefox/latest",
      "chrome/latest",
      "safari/6.0..latest",
      "iphone/6.0..latest",
      "android-browser/4.2..latest"
    ]
  },
  "version": "1.4.2"
}
